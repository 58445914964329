

// (더미)상태컬러
export const dummyStatusColorDataList = [
  { id:'TODO', text:'default' },
  { id:'DOING', text:'lightgreen' },
  { id:'DONE', text:'blue' },
  { id:'HOLD', text:'orange' },
];
export const dummyStatusIconColorDataList = [
  { id:'TODO', text:'#959595' },
  { id:'DOING', text:'#8BC34A' },
  { id:'DONE', text:'#3F51B4' },
  { id:'HOLD', text:'#FF7043' },
];
export const dummy_status_color = [
  { id:'DRAFT', text:'#F04848' },
  { id:'PUBLIC', text:'#388E3B' },
  { id:'CLOSED', text:'#CCCCCC' },
]

// 공통
export const common_boolean = [
  { id:true, text:'skyblue' },
  { id:false, text:'red' },
]
export const user_target = [
  { id:'ALL', text:'default' },
  { id:'PARENTS', text:'skyblue' },
  { id:'CHILD', text:'yellow' },
]

// (더미)상태2
export const dummy2StatusColorDataList = [
  { id:'High', text:'red' },
  { id:'Normal', text:'green' },
  { id:'Light', text:'blue' },
  { id:'None', text:'default' },
]

// 서비스 > 회원관리
export const user_status_name = [
  // { id:'ready', text:'대기중' },
  // { id:'active', text:'활성화' },
  // { id:'quit', text:'탈퇴' },
  // { id:'inacitve', text:'휴면' },
  // { id:'restricted', text:'제재' },
  { id:'EXPIRED', text:'gray' },
  { id:'PENDING', text:'yellow' },
  { id:'REGISTERED', text:'green' },
  { id:'UNREGISTERED', text:'red' },
]

// 서비스 > 멤버십관리
export const membership_type = [
  { id:'PLUS_USER', text:'purple' },
  { id:'BANK_ASSOCIATION', text:'blue' },
]

export const membership_purchase_method_type = [
  { id:'FREE', text:'skyblue' },
  { id:'REDEEM', text:'pink' },
  { id:'APPLE', text:'brown' },
  { id:'GOOGLE', text:'orange' },
  { id:'IMWEB', text:'green' },
]

export const membership_payment_status = [
  { id:'PAYMENT', text:'blue' },
  { id:'CANCEL', text:'red' },
]

// 서비스 > 약관 관리
export const user_role = [
  { id: 'PARENTS', text:'skyblue' },
  { id: 'CHILD', text:'yellow' },
]

// 서비스 > 약관 관리
export const term_status = [
  { id: 'REGIVATION', text:'yellow' },
  { id: 'TEMP', text:'gray' },
  { id: 'DEPLOIED', text:'blue' },
]

// 서비스 > 프로모션 코드 상태
export const promotion_status = [
  { id: 'PLAN', text:'yellow' },
  { id: 'ING', text:'blue' },
  { id: 'END', text:'default' },
  { id: 'HOLD', text:'orange' },
]

// 앱관리 > 광고관리
export const advertisement_status = [
  { id:'REGISTERED', text:'blue' },
  { id:'UNREGISTERED', text:'red' },
]
export const advertisement_inventory = [
  { id:'POPUP', text:'orange' },
  { id:'SECTION', text:'purple' },
  { id:'INTRO', text:'green' },
  { id:'CLOSE', text:'brown' },
]

// 앱관리 > 알림
export const alarm_status = [
  { id:'REGISTERED', text:'yellow' },
  { id:'UNREGISTERED', text:'orange' },
  { id:'RUNNING', text:'blue' },
  { id:'DONE', text:'default' },
  { id:'FAILED', text:'red' },
]
export const alarm_platform = [
  { id:'ALL', text:'default' },
  { id:'IOS', text:'blue' },
  { id:'ANDROID', text:'green' },
]

// 포인트 받기 관리 상태
export const more_point_status = [
  { id:'REGISTERED', text:'blue' },
  { id:'UNREGISTERED', text:'red' },
]

// 랜딩 페이지 관리 > 블로그 관리
export const blog_status = [
  { id:'NORMAL', text:'green' },
  { id:'HIDDEN', text:'red' },
]

// 랜딩 페이지 관리 > 블로그 관리 > 포스트 관리
export const post_type = [
  { id:'NORMAL', text:'default' },
  { id:'HOT', text:'red' },
]

// 직원관리 > 회원 권한
export const member_role = [
  { id:'ADMIN', text:'red' },
  { id:'USER', text:'green' },
]

// 직원관리 > 회원 권한
export const member_login_status = [
  { id:'OK', text:'primary' },
  { id:'LOCKED', text:'red' },
  { id:'NEED_MFA', text:'orange' },
]

// 직원관리 > 회원 권한 아이콘
export const member_login_status_icon = [
  { id:'OK', text:'PRIMARY_COLOR' },
  { id:'LOCKED', text:'RED_COLOR' },
  { id:'NEED_MFA', text:'ORANGE_COLOR' },
]

// 스케줄 타입 // APP, BANK, CONTENT, PAGE
export const scheduleTypeColorDataList = [
  { id:'GENERAL', text:'#FFD82A' },
  { id:'CONTENT', text:'#3DBF69' },
  { id:'APP', text:'#00CA2C' },
  { id:'PAGE', text:'#6CDFC3' },
  { id:'BANK', text:'#F04848' },
]

export const colorDataList = {
  // ** (더미)상태컬러
  dummy_status : dummyStatusColorDataList,
  dummyStatusIconColorDataList : dummyStatusIconColorDataList,
  dummy_status_color : dummy_status_color,
  dummy_status2 : dummy2StatusColorDataList,

  common_boolean : common_boolean,
  user_target : user_target,

  user_status_name : user_status_name,

  membership_type : membership_type,
  membership_purchase_method_type: membership_purchase_method_type,
  membership_payment_status : membership_payment_status,

  user_role : user_role,

  term_status : term_status,

  promotion_status : promotion_status, // 프로모션 코드 상태

  advertisement_status : advertisement_status,
  advertisement_inventory : advertisement_inventory,

  alarm_status: alarm_status,
  alarm_platform : alarm_platform,

  blog_status : blog_status,

  more_point_status : more_point_status,

  post_type : post_type,

  member_role : member_role, // 회원권한
  member_login_status : member_login_status, // 회원 로그인 상태
  member_login_status_icon : member_login_status_icon, // 회원 로그인 상태
  schedule_type: scheduleTypeColorDataList, // 스케줄 타입
}